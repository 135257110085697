const Loader = () => {
  return (
    <div className="flex h-full animate-pulse items-center justify-center">
      <svg
        width="166"
        height="180"
        viewBox="0 0 166 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M163.886 71.8836C160.1 50.345 154.519 29.1852 147.207 8.64062L109.377 71.8836H163.886Z"
          fill="#003A3A"
        />
        <path
          d="M67.2265 142.312L51.4102 168.774C63.3703 175.783 76.8166 179.638 90.5604 179.999C122.288 179.999 145.082 165.334 156.77 142.326L67.2265 142.312Z"
          fill="#003A3A"
        />
        <path
          d="M99.1771 88.9366L77.4531 125.252H163.049C163.958 121.529 164.633 117.749 165.07 113.936C166.014 105.638 166.24 97.2692 165.743 88.9297L99.1771 88.9366Z"
          fill="#003A3A"
        />
        <path
          d="M132.833 0C112.469 2.27074 92.3175 6.25077 72.5781 11.9007L95.9794 60.938L132.833 0Z"
          fill="#003A3A"
        />
        <path
          d="M13.8803 48.5547C-2.01692 69.7726 -5.18963 98.1004 9.23911 128.329C15.5871 140.946 24.8896 151.719 36.2981 159.665L53.401 131.379L13.8803 48.5547Z"
          fill="#003A3A"
        />
        <path
          d="M63.6901 114.349L85.7038 77.9434L56.8665 17.5625C48.7306 20.94 40.9279 25.1188 33.5663 30.0412C30.9862 31.7717 28.4971 33.6429 26.1094 35.6468L63.6901 114.349Z"
          fill="#003A3A"
        />
      </svg>
    </div>
  );
};

export default Loader;
