import {
  PolicyTypeEnum,
  WeeklyPracticeHours,
  InpcfParticipation,
  YesNoEnum,
  YearsPracticing,
  NewDentistType,
  PracticeProfile,
  YearsNoClaims,
  LeaveOfAbsence,
  ProfessionalService,
  InformedConsentType,
  Training,
  WhoAdministersAnesthesia,
  UsStateEnum,
  PlSubTypeEnum,
  FirstNamedInsuredType,
  WritingCompanyEnum,
  QuoteTypeEnum,
  PolicyPaymentPlanEnum,
  TransactionStatus,
  CoverageBasis,
  ConsentType,
  EntityType,
  EmployeeType,
  HoldHarmlessClause,
  MissionOfMercyEventType,
  PracticeIncomeWaitingPeriod,
  WindHailCoverage,
  NamedStormDeductible,
  EquipmentBreakdownDeductible,
  ElectronicEquipCoverage,
  StandardOrHigher,
  FloodCoverage,
  EarthquakeCoverage,
  EarthquakeBuildingClassification,
  LossAssessment,
  AccountsReceivableCoverage,
  SmokeDetectors,
  RoofType,
  CommercialCertification,
  EntityCoverage,
  SewerBackupCoverage,
  OrdinanceLimit,
  PracticeSpecialty,
  StopGapLimit,
  FireWaterLimit,
  MajorityOwned,
} from '@/lib/heyapi/types.gen';

import type { Selectable } from './components/Select';

// There's no type and const for ProfessionalDesignation, keeping consistent

export const ProfessionalDesignationTypeEnum = {
  BDS: 'BDS',
  DDS: 'DDS',
  DMD: 'DMD',
  MD: 'MD',
  MS: 'MD',
} as const;

export type ProfessionalDesignationTypeEnum =
  | 'BDS'
  | 'DDS'
  | 'DMD'
  | 'MD'
  | 'MS';

export const ProfessionalDesignationToLabel: Record<
  ProfessionalDesignationTypeEnum,
  string
> = {
  BDS: 'BDS',
  DDS: 'DDS',
  DMD: 'DMD',
  MD: 'MD',
  MS: 'MS',
};

export const EntityCoverageToLabel: Record<EntityCoverage, string> = {
  separate: 'Separate Limits',
  shared: 'Shared Limits',
  none: 'No Professional Liability Coverage Desired',
};

export const SelectableEntityCoverage: Array<Selectable<EntityCoverage>> =
  Object.values(EntityCoverage).map((v) => ({
    value: v,
    label: EntityCoverageToLabel[v],
  }));

export const SelectableProfessionalDesignation: Array<
  Selectable<ProfessionalDesignationTypeEnum>
> = Object.values(ProfessionalDesignationTypeEnum).map((v) => {
  return {
    value: v,
    label: ProfessionalDesignationToLabel[v],
  };
});

export const MercyEventTypeLabel: Record<MissionOfMercyEventType, string> = {
  all: 'All Health Care Providers',
  assistants_only: 'Hygienists & Dental Assistants Only',
};

export const SelectableMissionOfMercyEvent: Array<
  Selectable<MissionOfMercyEventType>
> = Object.values(MissionOfMercyEventType).map((v) => {
  return {
    value: v,
    label: MercyEventTypeLabel[v],
  };
});

//firstNameInsuredType
export const FirstNamedInsuredTypeToLabel: Record<
  FirstNamedInsuredType,
  string
> = {
  corporation: 'Corporation',
  individual: 'Individual',
  llc: 'LLC',
  llp: 'LLP',
  other: 'Other',
  partnership: 'Partnership',
  pc_pa: 'PC/PA',
};

export const SelectableFirstNamedInsuredType: Array<
  Selectable<FirstNamedInsuredType>
> = Object.values(FirstNamedInsuredType).map((v) => {
  return {
    value: v as FirstNamedInsuredType,
    label: FirstNamedInsuredTypeToLabel[v as FirstNamedInsuredType],
  };
});

// writingCompany
export const WritingCompanyToLabel: Record<WritingCompanyEnum, string> = {
  asic: 'Aspen Specialty Insurance Company (ASIC)',
  aaic: 'Aspen American Insurance Company (AAIC)',
};

export const SelectableWritingCompany: Array<Selectable<WritingCompanyEnum>> =
  Object.values(WritingCompanyEnum).map((v) => {
    return {
      value: v as WritingCompanyEnum,
      label: WritingCompanyToLabel[v],
    };
  });

// PolicyType
export const PolicyTypeToLabel: Record<PolicyTypeEnum, string> = {
  cancel_rewrite: 'Cancel/Rewrite',
  new_business: 'New Business',
  roll_over: 'Roll-Over',
  renewal: 'Renewal',
};

export const SelectablePolicyType: Array<Selectable<PolicyTypeEnum>> =
  Object.values(PolicyTypeEnum).map((v) => {
    return {
      value: v,
      label: PolicyTypeToLabel[v],
    };
  });

//quoteType
export const QuoteTypeToLabel: Record<QuoteTypeEnum, string> = {
  bindable_quote: 'Bindable Quote',
  preliminary_indication: 'Preliminary Indication',
  policy: 'Policy',
};

// User cannot select the policy type
export const SelectableQuoteType: Array<Selectable<QuoteTypeEnum>> =
  Object.values([
    QuoteTypeEnum.PRELIMINARY_INDICATION,
    QuoteTypeEnum.BINDABLE_QUOTE,
  ]).map((v) => {
    return {
      value: v,
      label: QuoteTypeToLabel[v],
    };
  });

//minimumEarnedPremium
export type MinimumEarnedPremium = '0%' | '25%' | '50%' | '100%';
export const premiums: Array<MinimumEarnedPremium> = [
  '0%',
  '25%',
  '50%',
  '100%',
];

export const SelectableMinimumEarnedPremium: Array<
  Selectable<MinimumEarnedPremium>
> = premiums.map((v) => {
  return {
    value: v,
    label: v.toString(),
  };
});

// paymentPlan
export const PaymentPlanLabels: Record<PolicyPaymentPlanEnum, string> = {
  full: 'Full Pay',
  quarterly: 'Quarterly (33%, 22.333%, 22.333%, 22.333%)',
};

export const SelectablePaymentPlan: Array<Selectable<PolicyPaymentPlanEnum>> =
  Object.values(PolicyPaymentPlanEnum).map((v) => {
    return {
      value: v,
      label: PaymentPlanLabels[v],
    };
  });

// Transaction statuses (not field)
export const TransactionStatusToLabel: Record<TransactionStatus, string> = {
  in_progress: 'In Progress',
  quoted: 'Quoted',
  active: 'Active',
  expired: 'Expired',
};

// coverageBasis
export const CoverageBasisToLabel: Record<CoverageBasis, string> = {
  occurrence: 'Occurrence',
  claims: 'Claims Made',
};

export const SelectableCoverageBasis: Array<Selectable<CoverageBasis>> =
  Object.values(CoverageBasis).map((v) => {
    return {
      value: v,
      label: CoverageBasisToLabel[v],
    };
  });

// consentType
export const ConsentTypeLabels: Record<ConsentType, string> = {
  first_named_insured: 'First Named Insured',
  insured_dentist: 'Insured Dentist',
};

export const SelectableConsentType: Array<Selectable<ConsentType>> =
  Object.values(ConsentType).map((v) => {
    return {
      value: v,
      label: ConsentTypeLabels[v],
    };
  });

// entityType
export const EntityTypeLabel: Record<EntityType, string> = {
  testing_board: 'Dental Testing Board',
  association: 'Dental Association',
};

export const SelectableEntityType: Array<Selectable<EntityType>> =
  Object.values(EntityType).map((v) => {
    return {
      value: v,
      label: EntityTypeLabel[v],
    };
  });

// employeeType
export const EmployeeTypeLabels: Record<EmployeeType, string> = {
  dentists: 'Dentists',
  oral_surgeons: 'Oral Surgeons',
  dental_anesthesiologists: 'Dental Anesthesiologists',
};

// holdHarmlessClause
export const HoldHarmlessLabels: Record<HoldHarmlessClause, string> = {
  no: 'No',
  yes: 'Yes',
  unsure: 'Unsure',
};

export const SelectableHoldHarmless: Array<Selectable<HoldHarmlessClause>> =
  Object.values(HoldHarmlessClause).map((v) => {
    return { value: v, label: HoldHarmlessLabels[v] };
  });

// plSubTypes
export const PLSubtypeToLabel: Record<PlSubTypeEnum, string> = {
  large_group: 'Large Group (10 Or More Dentists)',
  dental_association: 'Dental Association / Society',
  volunteer_only: 'Volunteer Only',
  faculty_only: 'Faculty Only (Non Intramural)',
  moonlighting: 'Moonlighting',
  new_grad: '2 Year New Grad Policy',
  non_large_dentist: 'Non Large Group (1 to 9 Dentist(s))',
  testing_board: 'Testing Board',
};

export const SelectablePlSubType: Array<Selectable<PlSubTypeEnum>> =
  Object.values(PlSubTypeEnum).map((v) => {
    return {
      value: v,
      label: PLSubtypeToLabel[v],
    };
  });

// export const SelectablePracticeSpecialty: Array<Selectable<PracticeSpecialtyEnum>> =
//   Object.values(PracticeSpecialtyEnum).map((v) => {
//     return {
//       value: v,
//       label: PracticeSpecialtyToLabel[v],
//     };
//   });

// export const WeeklyPracticeHoursToLabel: Record<WeeklyPracticeHours, string> = {

// };

// Use in case you'll need labels
// export const SelectableWeeklyPracticeHours: Array<Selectable<WeeklyPracticeHours>> =
//   Object.entries(WeeklyPracticeHours).map(([k, v]) => {
//     // console.log(k, v)
//     return {
//       value: v,
//       label: WeeklyPracticeHours[k as keyof typeof WeeklyPracticeHours],
//     };
//   });

// SelectablePlaceholder: TODO: Where it's used, it wasn't clear what options should have been used in dropdown
type PlaceholderEnum = 'options' | 'placeholder' | 'report';

export const PlaceholderEnum = {
  OPTIONS: 'options',
  PLACEHOLDER: 'placeholder',
  REPORT: 'report',
} as const;

export const PlaceholderToLabel: Record<PlaceholderEnum, string> = {
  options: 'Options were not defined',
  placeholder: 'Had to use placeholder',
  report: 'Report if found',
};

export const SelectablePlaceholder: Array<Selectable<PlaceholderEnum>> =
  Object.values(PlaceholderEnum).map((v) => {
    return {
      value: v,
      label: PlaceholderToLabel[v],
    };
  });

// YesNoEnum
export const SelectableYesNo: Array<Selectable<YesNoEnum>> = Object.entries(
  YesNoEnum
).map(([k, v]) => {
  return {
    value: v,
    label: YesNoEnum[k as keyof typeof YesNoEnum],
  };
});

const StopGapLabels: Record<StopGapLimit, string> = {
  '300k': '$300,000',
  '500k': '$500,000',
  '1m': '$1,000,000',
};

export const SelectableStopGapLimit: Array<Selectable<StopGapLimit>> =
  Object.values(StopGapLimit).map((v) => ({
    value: v,
    label: StopGapLabels[v],
  }));

const FireWaterLabels: Record<FireWaterLimit, string> = {
  '500k': '$500,000',
  '1m': '$1,000,000',
  '750k': '$750,000',
};

export const SelectableFireWaterLimit: Array<Selectable<FireWaterLimit>> =
  Object.values(FireWaterLimit).map((v) => ({
    value: v,
    label: FireWaterLabels[v],
  }));

// UsStateEnum

export const UnitedStatesToLabel: Record<UsStateEnum, string> = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  // AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  // FM: 'Federated States of Micronesia',
  GA: 'Georgia',
  // GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  // MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  // MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  // PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

export const SelectableUnitedStates: Array<Selectable<UsStateEnum>> =
  Object.values(UsStateEnum).map((v) => {
    return {
      value: v,
      label: UnitedStatesToLabel[v],
    };
  });

// IN PCF Fund Participation?
export const InpcfParticipationToLabel: Record<InpcfParticipation, string> = {
  refer: 'Refer to Agent',
  yes: 'Yes',
  no: 'No',
};

export const SelectableInpcfParticipation: Array<
  Selectable<InpcfParticipation>
> = Object.values(InpcfParticipation).map((v) => {
  return {
    value: v,
    label: InpcfParticipationToLabel[v],
  };
});

// Years In Practice
export const YearsPracticingToLabel: Record<YearsPracticing, string> = {
  y0: '0',
  y1: '1',
  y2: '2',
  y3: '3',
  gte4: '4+',
};

export const SelectableYearsPracticing: Array<Selectable<YearsPracticing>> =
  Object.values(YearsPracticing).map((v) => {
    return {
      value: v,
      label: YearsPracticingToLabel[v],
    };
  });

// New Dentist Type
export const NewDentistTypeToLabel: Record<NewDentistType, string> = {
  foreign: 'Foreign Dental School Graduate',
  new_grad: 'New Grad',
  military: 'Military Dentist',
  public: 'Public Service Sector',
};

export const SelectableNewDentistType: Array<Selectable<NewDentistType>> =
  Object.values(NewDentistType).map((v) => {
    return {
      value: v,
      label: NewDentistTypeToLabel[v],
    };
  });

// Number of Hours per Week in Practice
export const WeeklyPracticeHoursToLabel: Record<WeeklyPracticeHours, string> = {
  fulltime: 'Full-Time (More Than 20 Hours Per Week)',
  parttime: 'Part-Time (20 Hours Or Less)',
  moonlighting: 'Moonlighting (10 Hours Or Less Per Week And For Second Job)',
};

export const SelectableWeeklyPracticeHours: Array<
  Selectable<WeeklyPracticeHours>
> = Object.values(WeeklyPracticeHours).map((v) => {
  return {
    value: v,
    label: WeeklyPracticeHoursToLabel[v],
  };
});

//Practice Profile
export const PracticeProfileToLabel: Record<PracticeProfile, string> = {
  employee: 'Employee Dentist',
  faculty: 'Full-Time Faculty (No Intramural)',
  contractor: 'Independent Contractor Dentist',
  owner: 'Practice Owner',
  volunteer: 'Volunteer Only',
};

export const SelectablePracticeProfile: Array<Selectable<PracticeProfile>> =
  Object.values(PracticeProfile).map((v) => {
    return {
      value: v,
      label: PracticeProfileToLabel[v],
    };
  });

// yearsWithoutClaim
export const YearsNoClaimsToLabel: Record<YearsNoClaims, string> = {
  y0: '0',
  y1: '1',
  y2: '2',
  y3: '3',
  y4: '4',
  y5: '5',
  y6: '6',
  y7: '7',
  y8: '8',
  y9: '9',
  gte10: '10+',
};

export const SelectableYearsNoClaims: Array<Selectable<YearsNoClaims>> =
  Object.values(YearsNoClaims).map((v) => {
    return {
      value: v,
      label: YearsNoClaimsToLabel[v],
    };
  });

// Leave of Absence
export const LeaveOfAbsenceToLabel: Record<LeaveOfAbsence, string> = {
  none: 'None',
  initiate_regular: 'Initiate Regular',
  initiate_military: 'Initiate Military',
  reinstate_regular: 'Reinstate Professional Liability (REGULAR)',
  reinstate_military: 'Reinstate Professional Liability (MILITARY)',
};

// TODO: BE: rules: When previous transaction "Leave of Absence" INCLUDES ("INITIATE REGULAR")
// "INITIATE REGULAR"
// When previous transaction "Leave of Absence" INCLUDES ("INITIATE MILITARY")
// "INITIATE MILITARY"
// Note: Latest transaction does not have this data, or should it be taken from general fields data? Seems to be equal

export const SelectableLeaveOfAbsence: Array<Selectable<LeaveOfAbsence>> =
  Object.values(LeaveOfAbsence).map((v) => {
    return {
      value: v,
      label: LeaveOfAbsenceToLabel[v],
    };
  });

//professionalServices
export const ProfessionalServiceToLabel: Record<ProfessionalService, string> = {
  alternative: 'Holistic or Alternative Dental Service',
  cbct: 'Use of Dental Cone-Beam Computed Tomography (CBCT) Imaging Teledentistry',
  elective: 'Elective Cosmetic Dermal Procedures',
  locum_tenens: 'Services Rendered as a Full-Time Locum Tenens',
  iv_sedation:
    'Treatment of Patients Under IV/IM Conscious Sedation in the Insured’s Office',
  oral_sedation:
    'Treatment of Patients Under Oral Conscious Sedation in the Insured’s Office',
  apnea: 'Treatment of Sleep Apnea Without a Physician Referral',
  tele: 'Teledentistry',
  temp: 'Services Rendered on Behalf of a Temporary Staffing Agency',
};

export const SelectableProfessionalService: Array<
  Selectable<ProfessionalService>
> = Object.values(ProfessionalService).map((v) => {
  return {
    value: v,
    label: ProfessionalServiceToLabel[v],
  };
});

// informedConsentTypes
export const InformedConsentTypeToLabel: Record<InformedConsentType, string> = {
  written: 'Written',
  oral: 'Oral',
  none: 'None',
};

export const SelectableInformedConsentType: Array<
  Selectable<InformedConsentType>
> = Object.values(InformedConsentType).map((v) => {
  return {
    value: v,
    label: InformedConsentTypeToLabel[v],
  };
});

// trainings
export const TrainingToLabel: Record<Training, string> = {
  ce: 'CE',
  school: 'Dental School',
  post_grad: 'Post Grad',
};

export const SelectableTraining: Array<Selectable<Training>> = Object.values(
  Training
).map((v) => {
  return {
    value: v,
    label: TrainingToLabel[v],
  };
});

// whoAdministersAnesthesia
export const WhoAdministersAnesthesiaToLabel: Record<
  WhoAdministersAnesthesia,
  string
> = {
  another: 'Another Licensed Professional',
  insured: 'Insured Dentist',
};

export const SelectableWhoAdministersAnesthesia: Array<
  Selectable<WhoAdministersAnesthesia>
> = Object.values(WhoAdministersAnesthesia).map((v) => {
  return {
    value: v,
    label: WhoAdministersAnesthesiaToLabel[v],
  };
});

export const PracticeSpecialtyToLabel: Record<PracticeSpecialty, string> = {
  oral_surgery: 'Oral Surgeon',
  anesthesiologist: 'Dental Anesthesiologist',
  orthodontics: 'Orthodontics',
  endodontics: 'Endodontics',
  general: 'General Dentistry',
  pediatric: 'Pediatric Dentistry',
  periodontics: 'Periodontics',
  prosthodontics: 'Prosthodontics',
  public: 'Public Health',
  oral_medicine: 'Oral Medicine',
  forensic: 'Forensic Dentistry',
  oral_radiology: 'Oral Radiology',
  orofacial: 'Orofacial Pain',
  oral_pathology: 'Oral Pathology',
};

export const SelectablePracticeSpecialty: Array<Selectable<PracticeSpecialty>> =
  Object.values(PracticeSpecialty).map((v) => {
    return {
      value: v,
      label: PracticeSpecialtyToLabel[v],
    };
  });

// practiceIncomeWaitingPeriod
export const PracticeIncomeWaitingPeriodToLabel: Record<
  PracticeIncomeWaitingPeriod,
  string
> = {
  zero: '0 Hours (0 Days)',
  twenty_four: '24 Hours (1 Day)',
  forty_eight: '48 Hours (2 Days)',
  seventy_two: '72 Hours (3 Days)',
  ninety_six: '96 Hours (4 Days)',
  one_twenty: '120 Hours (5 Days)',
  one_fourty_four: '144 Hours (6 Days)',
  one_sixty_eight: '168 Hours (7 Days)',
  one_ninety_six: '192 Hours (8 Days)',
  two_sixteen: '216 Hours (9 Days)',
  two_forty: '240 Hours (10 Days)',
  three_sixty: '360 Hours (15 Days)',
  four_eighty: '480 Hours (20 Days)',
  six_hundred: '600 Hours (25 Days)',
};

export const SelectablePracticeIncomeWaitingPeriod: Array<
  Selectable<PracticeIncomeWaitingPeriod>
> = Object.values(PracticeIncomeWaitingPeriod).map((v) => {
  return {
    value: v,
    label: PracticeIncomeWaitingPeriodToLabel[v],
  };
});

// windhailCoverage
export const WindHailCoverageToLabel: Record<WindHailCoverage, string> = {
  location_fixed_deductible: 'Location Fixed Deductible Above',
  one_pct: '1%',
  '2%': '2%',
  five_pct: '5%',
  excluded: 'Wind/Hail Excluded',
};

export const SelectableWindHailCoverage: Array<Selectable<WindHailCoverage>> =
  Object.values(WindHailCoverage).map((v) => {
    return {
      value: v,
      label: WindHailCoverageToLabel[v],
    };
  });

// namedStormDeductible
export const NamedStormDeductibleToLabel: Record<NamedStormDeductible, string> =
  {
    none: 'NONE',
    one_pct: '1%',
    two_pct: '2%',
    '5%': '5%',
  };

export const SelectableNamedStormDeductible: Array<
  Selectable<NamedStormDeductible>
> = Object.values(NamedStormDeductible).map((v) => {
  return {
    value: v,
    label: NamedStormDeductibleToLabel[v],
  };
});

// equipmentBreakdownDeductible
export const EquipmentBreakdownDeductibleToLabel: Record<
  EquipmentBreakdownDeductible,
  string
> = {
  1000: '$1,000',
  2500: '$2,500',
  5000: '$5,000',
  10000: '$10,000',
};

export const SelectableEquipmentBreakdownDeductible: Array<
  Selectable<EquipmentBreakdownDeductible>
> = Object.values(EquipmentBreakdownDeductible).map((v) => {
  return {
    value: v,
    label: EquipmentBreakdownDeductibleToLabel[v],
  };
});

// electronicEquipCoverage
export const ElectronicEquipCoverageToLabel: Record<
  ElectronicEquipCoverage,
  string
> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
  none: 'None',
};

export const SelectableElectronicEquipCoverage: Array<
  Selectable<ElectronicEquipCoverage>
> = Object.values(ElectronicEquipCoverage).map((v) => {
  return {
    value: v,
    label: ElectronicEquipCoverageToLabel[v],
  };
});

// TODO: sewerBackupCoverage StandardOrHigher missing enum options, should be: "No", "Standard Limit", "$50,000", "$100,000", "Higher Limit"
// sewerBackupCoverage
export const SewerBackupCoverageToLabel: Record<SewerBackupCoverage, string> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
  '50k': '$50,000',
  '100k': '$100,000',
};

export const SelectableSewerBackupCoverage: Array<
  Selectable<SewerBackupCoverage>
> = Object.values(SewerBackupCoverage).map((v) => {
  return {
    value: v,
    label: SewerBackupCoverageToLabel[v],
  };
});

// pollutionLimitCoverage
export const PollutionLimitCoverageToLabel: Record<StandardOrHigher, string> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
};

export const SelectablePollutionLimitCoverage: Array<
  Selectable<StandardOrHigher>
> = Object.values(StandardOrHigher).map((v) => {
  return {
    value: v,
    label: PollutionLimitCoverageToLabel[v],
  };
});

// floodCoverage
export const FloodCoverageToLabel: Record<FloodCoverage, string> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
  not_eligible: 'Not Eligible',
};

export const SelectableFloodCoverage: Array<Selectable<FloodCoverage>> =
  Object.values(FloodCoverage).map((v) => {
    return {
      value: v,
      label: FloodCoverageToLabel[v],
    };
  });

// earthquakeCoverage
export const EarthquakeCoverageToLabel: Record<EarthquakeCoverage, string> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
  not_eligible: 'Not Eligible',
  none: 'None',
  desired: 'Coverage Desired',
};

export const SelectableEarthquakeCoverage: Array<
  Selectable<EarthquakeCoverage>
> = Object.values(EarthquakeCoverage).map((v) => {
  return {
    value: v,
    label: EarthquakeCoverageToLabel[v],
  };
});

// earthquakeBuildingClass
export const EarthquakeBuildingClassificationToLabel: Record<
  EarthquakeBuildingClassification,
  string
> = {
  A1: 'Class A1',
  B1: 'Class B1',
  C1: 'Class C1',
  D1: 'Class D1',
  D2: 'Class D2',
  D3: 'Class D3',
  E1: 'Class E1',
  E2: 'Class E2',
  E3: 'Class E3',
};

export const SelectableEarthquakeBuildingClassification: Array<
  Selectable<EarthquakeBuildingClassification>
> = Object.values(EarthquakeBuildingClassification).map((v) => {
  return {
    value: v,
    label: EarthquakeBuildingClassificationToLabel[v],
  };
});

// lossAssessmentCoverage
export const LossAssessmentToLabel: Record<LossAssessment, string> = {
  higher: 'Higher Limit',
  no: 'No',
  one_k: '$1,000',
  ten_k: '$10,000',
  five_k: '$5,000',
};

export const SelectableLossAssessment: Array<Selectable<LossAssessment>> =
  Object.values(LossAssessment).map((v) => {
    return {
      value: v,
      label: LossAssessmentToLabel[v],
    };
  });

// offPremCoverage
export const OffPremCoverageToLabel: Record<StandardOrHigher, string> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
};

export const SelectableOffPremCoverage: Array<Selectable<StandardOrHigher>> =
  Object.values(StandardOrHigher).map((v) => {
    return {
      value: v,
      label: OffPremCoverageToLabel[v],
    };
  });

// accountsReceivableCoverage
export const AccountsReceivableCoverageToLabel: Record<
  AccountsReceivableCoverage,
  string
> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
};

export const SelectableAccountsReceivableCoverage: Array<
  Selectable<AccountsReceivableCoverage>
> = Object.values(AccountsReceivableCoverage).map((v) => {
  return {
    value: v,
    label: AccountsReceivableCoverageToLabel[v],
  };
});

// smokeDetectors
export const SmokeDetectorsToLabel: Record<SmokeDetectors, string> = {
  no: 'No Smoke Detector',
  central: 'Central Smoke Detector',
  local: 'Local Smoke Detector',
};

export const SelectableSmokeDetectors: Array<Selectable<SmokeDetectors>> =
  Object.values(SmokeDetectors).map((v) => {
    return {
      value: v,
      label: SmokeDetectorsToLabel[v],
    };
  });

// roofType
export const RoofTypeToLabel: Record<RoofType, string> = {
  metal: 'Metal',
  other: 'Other than Metal',
};

export const SelectableRoofType: Array<Selectable<RoofType>> = Object.values(
  RoofType
).map((v) => {
  return {
    value: v,
    label: RoofTypeToLabel[v],
  };
});

// fortifiedCommercialCertification
export const FortifiedCommercialCertificationToLabel: Record<
  CommercialCertification,
  string
> = {
  bronze: 'Bronze/Roof Certification',
  silver: 'Silver Certification',
  gold: 'Gold Certification',
  not_applicable: 'Not Applicable',
};

export const SelectableFortifiedCommercialCertification: Array<
  Selectable<CommercialCertification>
> = Object.values(CommercialCertification).map((v) => {
  return {
    value: v,
    label: FortifiedCommercialCertificationToLabel[v],
  };
});

// signsCoverage
export const SignsCoverageToLabel: Record<StandardOrHigher, string> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
};

export const SelectableSignsCoverage: Array<Selectable<StandardOrHigher>> =
  Object.values(StandardOrHigher).map((v) => {
    return {
      value: v,
      label: SignsCoverageToLabel[v],
    };
  });

// demolitionIncreasedLimitDesired
export const DemolitionIncreasedLimitDesiredToLabel: Record<
  OrdinanceLimit,
  string
> = {
  standard: 'Standard Limit ($100,000 Combined 1, 2, 3)',
  higher: 'Higher Limit',
};

export const SelectableDemolitionIncreasedLimitDesired: Array<
  Selectable<OrdinanceLimit>
> = Object.values(OrdinanceLimit).map((v) => {
  return {
    value: v,
    label: DemolitionIncreasedLimitDesiredToLabel[v],
  };
});

// majorityOwned
export const MajorityOwnedToLabel: Record<MajorityOwned, string> = {
  'N/A': 'N/A',
  Yes: 'Yes',
  No: 'No',
};

export const SelectableMajorityOwned: Array<Selectable<MajorityOwned>> =
  Object.values(MajorityOwned).map((v) => {
    return {
      value: v,
      label: MajorityOwnedToLabel[v],
    };
  });

// employeeDishonestyCoverageChoice
export const EmployeeDishonestyCoverageChoiceToLabel: Record<
  StandardOrHigher,
  string
> = {
  standard: 'Standard Limit',
  higher: 'Higher Limit',
};

export const SelectableEmployeeDishonestyCoverageChoice: Array<
  Selectable<StandardOrHigher>
> = Object.values(StandardOrHigher).map((v) => {
  return {
    value: v,
    label: EmployeeDishonestyCoverageChoiceToLabel[v],
  };
});
