import { getAuth, login, logout } from './allauth';

/**
 * Auth interface to match the design of react-router
 * https://github.com/remix-run/react-router/blob/dev/examples/auth-router-provider/src/App.tsx
 *
 * Additional methods for forgot password, etc., should be added
 */
interface AuthProvider {
  isAuthenticated: boolean;
  checkAuth(): Promise<boolean>;
  username: null | string;

  login(data: any): Promise<void>;
  logout(): Promise<void>;
}

export const allAuthProvider: AuthProvider = {
  isAuthenticated: false,
  username: null,

  async checkAuth() {
    const msg = await getAuth();

    if ([401, 410].includes(msg.status)) {
      allAuthProvider.username = null;
      allAuthProvider.isAuthenticated = false;
      return false;
    }

    if (msg.status === 200 && msg.meta.is_authenticated) {
      allAuthProvider.username = msg.data.user.email;
      allAuthProvider.isAuthenticated = true;
      return true;
    }

    console.warn(`Authentication failed unexpectedly`, msg);
    return false;
  },

  async login(data) {
    const msg = await login(data);

    if ([401, 410].includes(msg.status)) {
      allAuthProvider.username = null;
      allAuthProvider.isAuthenticated = false;
    }

    if (msg.status === 200 && msg.meta.is_authenticated) {
      allAuthProvider.username = msg.data.user.email;
      allAuthProvider.isAuthenticated = true;
    }
  },
  async logout() {
    await logout();

    allAuthProvider.username = null;
    allAuthProvider.isAuthenticated = false;
  },
};
