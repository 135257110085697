const formatter = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

export function formatDate(d: string): string {
  // This method sucks to have. For some reason, we are getting different formatted strings back
  // from the backend. SOMETIMES it is hyphenated (seems to be wherever we SET the date like
  // on Policy Details.
  // Other spots, like Location Effective Date, comes back formatted correctly by default

  try {
    let splitByHyphen = d.split('-');
    let splitBySlash = d.split('/');

    if (splitBySlash.length == 3) {
      return d;
    }

    if (splitByHyphen.length == 3) {
      return [splitByHyphen[1], splitByHyphen[2], splitByHyphen[0]].join('/');
    }
  } catch (Error) {
    return d;
  }

  return d;
}

export function toDateString(d: Date | string): string {
  try {
    let newDate = new Date(d);
    const offset = newDate.getTimezoneOffset();
    newDate = new Date(newDate.getTime() - offset * 60 * 1000);
    return newDate.toISOString().split('T')[0];
  } catch (e) {
    return '';
  }
}
