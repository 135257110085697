export const headers: Headers = new Headers();

export const CF_HEADERS: Array<[string, string]> = [
  ['CF-Access-Client-Id', import.meta.env.VITE_CF_ACCESS_CLIENT_ID],
  ['CF-Access-Client-Secret', import.meta.env.VITE_CF_ACCESS_CLIENT_SECRET],
];

export function defaultHeaders() {
  const headers: Headers = new Headers();

  CF_HEADERS.forEach(([k, v]) => headers.append(k, v));

  return headers;
}

export function defaultHeadersInit() {
  const init: HeadersInit = {
    'CF-Access-Client-Id': import.meta.env.VITE_CF_ACCESS_CLIENT_ID,
    'CF-Access-Client-Secret': import.meta.env.VITE_CF_ACCESS_CLIENT_SECRET,
  };

  return init;
}
