import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@/lib/components/theme-provider';
import { Toaster } from '@/components/ui/toaster';

import Loader from '../components/Loader';
import Sidebar from './Sidebar';
import Meta from './Meta';

const Layout = () => {
  return (
    <ThemeProvider>
      <Meta />
      <div className="flex flex-col dark:bg-black dark:text-white">
        {/* <Header /> */}
        <Sidebar />
        <div className="flex flex-col pl-[20vw]">
          <main className="wrapper ml-0 w-full max-w-full">
            {/* TODO: See if shads class "wrapper" is better */}
            {/* TODO: Add fallback component */}
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </main>
          <Toaster />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
