import { Button } from '@/components/ui/button';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Page404 from '../404';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

const RootErrorBoundary = () => {
  const error = useRouteError() as Error;

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  if (isRouteErrorResponse(error)) {
    if (error.status == 404) {
      return <Page404 />;
    }
  }

  return (
    <div className="bg-brand-dark-green pt-9">
      <div className="flex min-h-[10vh] pl-4">
        <Link to="/" key="Home" className="ml-[16px]">
          <img src="/assets/aspen.svg" alt="Aspen logo" />
        </Link>
      </div>

      <div className="grid min-h-[90vh] items-center justify-center gap-2 bg-planets text-white">
        <img
          width={400}
          src="/assets/unexpected_error.svg"
          alt="An unexpected error has occurred"
        />

        <div className="grid items-center gap-4 self-start text-center">
          <h1 className="text-5xl">Ooops!</h1>

          <div className="grid gap-6">
            <p>An unexpected error has occurred</p>
            <div>
              <Button className="bg-white text-black">
                <Link to="/">Back to Dashboard</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootErrorBoundary;
