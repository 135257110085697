import { LoaderFunctionArgs } from 'react-router-dom';
import {
  glacierPolicyApiGetPolicyOptions,
  glacierPolicyApiGetPolicySummaryOptions,
} from '../heyapi/@tanstack/react-query.gen';
import { QueryClient } from '@tanstack/react-query';
import { CF_HEADERS, defaultHeaders } from '../fetch_utils';
import { glacierPolicyApiGetPolicySummary } from '../heyapi';

/**
 * react-query + router loader example taken from:
 *
 * https://tanstack.com/query/latest/docs/framework/react/examples/react-router
 * https://tkdodo.eu/blog/react-query-meets-react-router
 *
 * Pages should fetch using react-query based on the ID via useSuspenseQuery
 */
export const loader =
  (qc: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    await qc.ensureQueryData(
      glacierPolicyApiGetPolicySummaryOptions({
        fetch: (request) => {
          return fetch(request, {
            credentials: 'include',
            headers: CF_HEADERS,
          });
        },
      })
    );
    return;
  };
