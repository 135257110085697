import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { ThemeProvider } from '@/lib/components/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import Loader from '../components/Loader';
import Footer from './Footer';
import Header from './Header';
import Meta from './Meta';

const Layout = () => {
  return (
    <ThemeProvider>
      <Meta />
      <div className="flex flex-col dark:bg-black dark:text-white">
        <Header />
        <div className="flex flex-col pl-[20vw]">
          <Sidebar />
          <main className="wrapper mx-auto w-full max-w-full pt-0">
            {' '}
            {/* w-[93%] */}
            {/* TODO: See if shads class "wrapper" is better */}
            {/* TODO: Add fallback component */}
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </main>
          <Footer />
          <Toaster />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
