import { RouterProvider } from 'react-router-dom';
import { router } from '@/lib/router/routes';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { client } from './lib/heyapi/services.gen';
import { CF_HEADERS, defaultHeadersInit } from './lib/fetch_utils';

const App = () => {
  // Configures the Hey API client to talk to our backend
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: 1000 * 10,
        retry: 2,
      },
    },
  });

  client.setConfig({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    credentials: 'include',
    headers: defaultHeadersInit(),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
