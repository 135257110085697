import React from 'react';
import {
  LoaderFunctionArgs,
  createBrowserRouter,
  redirect,
  type PathRouteProps,
} from 'react-router-dom';
import ApplicationLayout from '@/lib/layout';
import DashboardLayout from '@/lib/dashboardLayout';
import { Policy } from '../heyapi';
import { allAuthProvider } from '../auth/auth';
import * as Sentry from '@sentry/react';
import RootErrorBoundary from '../pages/Error';
import { QueryClient } from '@tanstack/react-query';
import { loader as policyLoaderV2 } from './policyLoader.ts';
import { loader as summaryLoader } from './policySummaryLoader.ts';
import { defaultHeaders } from '../fetch_utils.ts';

const Home = React.lazy(() => import('@/lib/pages/home'));
const PolicyDetails = React.lazy(
  () => import('@/lib/pages/forms/policyDetails')
);

const PracticeLocations = React.lazy(
  () => import('@/lib/pages/forms/practiceLocations')
);

const ProfLiabCoverage = React.lazy(
  () => import('@/lib/pages/forms/plCoverageSection')
);

const OwnedEntities = React.lazy(
  () => import('@/lib/pages/forms/ownedEntities')
);

const Epli = React.lazy(() => import('@/lib/pages/forms/epli'));

const GeneralLiability = React.lazy(
  () => import('@/lib/pages/forms/generalLiability')
);

const PracticeOwner = React.lazy(
  () => import('@/lib/pages/forms/plPracticeOwners')
);

const ERISAFidAndEmployee = React.lazy(() => import('@/lib/pages/forms/erisa'));

const CommercialPropertyLocations = React.lazy(
  () => import('@/lib/pages/forms/commercialPropertyLocations')
);

const CommercialPropertyCoverages = React.lazy(
  () => import('@/lib/pages/forms/commercialPropertyCoverages')
);

const PolicyDecisions = React.lazy(
  () => import('@/lib/pages/forms/policyDecisions')
);

const DocumentWarehouse = React.lazy(
  () => import('@/lib/pages/forms/documentWarehouse')
);

const ProfLiabDentist = React.lazy(
  () => import('@/lib/pages/forms/profLiabDentist')
);

const VicariousLiabOwners = React.lazy(
  () => import('@/lib/pages/forms/vicariousLiabOwners')
);

const Claims = React.lazy(() => import('@/lib/pages/forms/claims'));

const Login = React.lazy(() => import('@/lib/pages/login'));

const ResetPassword = React.lazy(
  () => import('@/lib/auth/components/ResetPassword')
);

const Page404 = React.lazy(() => import('@/lib/pages/404'));

const Profile = React.lazy(() => import('@/lib/pages/profile/index'));

const Renewals = React.lazy(() => import('@/lib/pages/renewals/index'));

async function protectedLoader({ request }: LoaderFunctionArgs) {
  await allAuthProvider.checkAuth();
  if (!allAuthProvider.isAuthenticated) {
    let params = new URLSearchParams();
    params.set('from', new URL(request.url).pathname);
    return redirect('/auth/login?' + params.toString());
  }

  return allAuthProvider;
}

const BASE_URL = `${import.meta.env.VITE_BACKEND_URL}/api/v1/policy`;

const qc = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// TODO: Protect everything within policy
// For loader to execute correctly, the elements must be within the page NOT within the layout!
export const router = sentryCreateBrowserRouter([
  {
    path: 'auth/login',
    element: <Login />,
    loader: () => allAuthProvider.checkAuth(),
  },
  {
    path: 'auth/reset-password',
    element: <ResetPassword />,
  },

  {
    path: '*',
    element: <Page404 />,
  },

  {
    id: 'root',
    element: <DashboardLayout />,
    path: '/',
    loader: protectedLoader,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />,
        loader: summaryLoader,
      },
      {
        path: 'load-renewals',
        element: <Renewals />,
      },
      {
        path: 'profile',
        element: <Profile />,

        loader: protectedLoader,
      },
    ],
  },
  {
    id: 'policy-root',
    loader: protectedLoader,
    path: '/policy/:id',
    element: <ApplicationLayout />,
    errorElement: <RootErrorBoundary />,

    children: [
      {
        path: 'policy-details',
        element: <PolicyDetails />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'practice-locations',
        element: <PracticeLocations />,
        loader: async ({ params, request }: LoaderFunctionArgs) => {
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const locations = policy.locations || [];
          const location = locations.at(0);

          if (location) {
            return redirect(`${request.url}/${location.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'practice-locations/:locationId',
        element: <PracticeLocations />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'prof-liability-coverage',
        element: <ProfLiabCoverage />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'prof-liability-dentist',
        element: <ProfLiabDentist />,
        loader: async ({ params, request }: LoaderFunctionArgs) => {
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();

          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const dentists = policy.dentists || [];
          const dentist = dentists.at(0);

          if (dentist) {
            return redirect(`${request.url}/${dentist.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'prof-liability-dentist/:dentistId',
        element: <ProfLiabDentist />,
        loader: policyLoaderV2(qc),
      },

      {
        path: 'prof-liability-practice-owners',
        element: <PracticeOwner />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'owned-entities',
        element: <OwnedEntities />,
        loader: async ({ params, request }: LoaderFunctionArgs) => {
          // TODO: Change to actual request once API is ready
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const entities = policy.ownedEntities || [];
          const entity = entities.at(0);

          if (entity) {
            return redirect(`${request.url}/${entity.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'owned-entities/:entityId',
        element: <OwnedEntities />,
        loader: policyLoaderV2(qc),
      },

      {
        path: 'epli',
        element: <Epli />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'general-liability',
        loader: policyLoaderV2(qc),
        element: <GeneralLiability />,
      },
      {
        path: 'erisa-fiduciary-and-employee-benefits',
        element: <ERISAFidAndEmployee />,
        loader: policyLoaderV2(qc),
      },
      // {
      //   path: 'commercial-property-locations',
      //   element: <CommercialPropertyLocations />,
      // },
      {
        path: 'commercial-property-locations',
        element: <CommercialPropertyLocations />,
        loader: async ({ params, request }) => {
          // TODO: Change to actual request once API is ready
          const { id } = params;

          if (!id) {
            return null;
          }

          const headers = defaultHeaders();
          headers.append('Accept', 'application/json');

          const options: RequestInit = {
            credentials: 'include',
            headers,
            method: 'GET',
          };
          const data = await fetch(`${BASE_URL}/${id}`, options);

          const policy = (await data.json()) as Policy;

          const locations = policy.commercialProperty?.locations || [];
          const location = locations.at(0);

          if (location) {
            return redirect(`${request.url}/${location.pk}`);
          }

          return { policyId: id };
        },
      },
      {
        path: 'commercial-property-locations/:locationId',
        element: <CommercialPropertyLocations />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'commercial-property-coverages',
        element: <CommercialPropertyCoverages />,
        loader: policyLoaderV2(qc),
      },
      {
        path: 'policy-decisions',
        element: <PolicyDecisions />,

        loader: policyLoaderV2(qc),
      },
      {
        path: 'document-warehouse',
        element: <DocumentWarehouse />,
      },
      {
        path: 'claims',
        element: <Claims />,
      },
    ],
  },
]);

export const privateRoutes: Array<PathRouteProps> = [];
