import { LoaderFunctionArgs } from 'react-router-dom';
import { glacierPolicyApiGetPolicyOptions } from '../heyapi/@tanstack/react-query.gen';
import { QueryClient } from '@tanstack/react-query';
import { CF_HEADERS, defaultHeaders } from '../fetch_utils';

/**
 * react-query + router loader example taken from:
 *
 * https://tanstack.com/query/latest/docs/framework/react/examples/react-router
 * https://tkdodo.eu/blog/react-query-meets-react-router
 *
 * Pages should fetch using react-query based on the ID via useSuspenseQuery
 */
export const loader =
  (qc: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = params.id;

    if (!id) {
      throw new Error('No ID provided');
    }

    await qc.ensureQueryData(
      glacierPolicyApiGetPolicyOptions({
        path: { account_id: id },
        fetch: (request) => {
          return fetch(request, {
            credentials: 'include',
            headers: CF_HEADERS,
          });
        },
      })
    );
    return { policyId: id };
  };
