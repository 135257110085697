import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <div className="bg-brand-dark-green pt-9">
      <div className="flex min-h-[10vh] pl-4">
        <Link to="/" key="Home" className="ml-[16px]">
          <img src="/assets/aspen.svg" alt="Aspen logo" />
        </Link>
      </div>

      <div className="grid min-h-[90vh] items-center justify-center gap-2 bg-planets text-white">
        <img
          width={400}
          src="/assets/404.svg"
          alt="The page requested was not found"
        />

        <div className="grid items-center gap-4 self-start text-center">
          <h1 className="text-5xl">Ooops!</h1>

          <div className="grid gap-6">
            <p>The page you were looking for was not found</p>
            <div>
              <Button className="bg-white text-black">
                <Link to="/">Go to homepage</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
